<template>
  <v-dialog :value="value" @input="close" max-width="600px" :persistent="isPersistent">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <h3>{{$t('settings.documents-generator.fm-signature.mainDialog.title')}}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row no-gutters class="mt-6">

            <!-- Firstname -->
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                :rules="requiredRules"
                :label="$t('settings.documents-generator.fm-signature.labels.firstname')"
                maxlength="50"
                @change="firstnameUpdated"
                v-model="form.firstname" />
            </v-col>

            <!-- Name -->
            <v-col cols="12" md="6">
              <v-text-field
                  outlined
                  :rules="requiredRules"
                  :label="$t('settings.documents-generator.fm-signature.labels.name')"
                  maxlength="50"
                  @change="nameUpdated"
                  v-model="form.name" />
            </v-col>

            <!-- Position -->
            <v-col cols="12">
              <v-text-field
                outlined
                :rules="requiredRules"
                :label="$t('settings.documents-generator.fm-signature.labels.position')"
                maxlength="50"
                v-model="form.position" />
            </v-col>

            <!-- Email -->
            <v-col cols="12">
              <v-text-field
                outlined
                :rules="requiredRules"
                :label="$t('settings.documents-generator.fm-signature.labels.email')"
                maxlength="50"
                v-model="form.email" />
            </v-col>

            <!-- Phone -->
            <v-col cols="12">
              <v-text-field
                outlined
                :rules="requiredRules"
                :label="$t('settings.documents-generator.fm-signature.labels.phone')"
                maxlength="50"
                v-model="form.phone" />
            </v-col>

            <v-col cols="12">
              <AlertBar />
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row justify="space-around" class="ma-2">

          <!-- Cancel -->
          <v-btn @click="close" v-if="!isPersistent">{{ $t('common.buttons.cancel') }}</v-btn>

          <v-spacer />

          <!-- Save -->
          <v-btn
            :loading="isLoadingBtn.btnSave"
            @click="customSubmit"
            class="btn-primary"
            color="primary">
            {{ $t('settings.documents-generator.fm-signature.mainDialog.submit')}}
          </v-btn>
        </v-row>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog"
import formRules from "@/mixins/formRules";

export default {
  name: "FMSignatureGeneratorMainDialog",

  mixins: [dialogMixin, formRules],

  props: {
    isPersistent: { type: Boolean, default: false }
  },

  components: {
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
  },

  data() {
    return {
      isLoadingBtn: {btnSave: false},

      defaultForm: {
        firstname: "",
        name: "",
        position: "",
        email: "",
        phone: ""
      }
    }
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        this.$http.post(`/settings/documents-generator/fm-signature`, this.form, {
          responseType: 'arraybuffer',
          headers: {
            Authorization: "Bearer " + this.$session.get('jwt')
          }
        })
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `signature_fairlymade_${this.form.firstname}.html`);
            // document.body.appendChild(link);
            link.click();
            link.remove();
            if (!this.isPersistent)
              this.close();
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtn.btnSave = false;
          });
      }
    },
    firstnameUpdated(firstname) {
      if (this.form.email === "") {
        this.form.email = `${firstname.toLowerCase()}.${this.form.name.toLowerCase()}@fairlymade.com`;
      }
    },

    nameUpdated(name) {
      if (this.form.email === "" || this.form.email === `${this.form.firstname.toLowerCase()}.@fairlymade.com`)
        this.form.email = `${this.form.firstname.toLowerCase()}.${name.toLowerCase()}@fairlymade.com`;
    }
  }
}
</script>

<style scoped>

</style>
